import { ReportHandler, getCLS, getFID, getFCP, getLCP, getTTFB } from 'web-vitals';

/**
 * Inicializa el monitoreo del rendimiento para las métricas vitales de la web.
 * Utiliza importaciones dinámicas para cargar la biblioteca de métricas web solo cuando es necesario.
 * 
 * @param onPerfEntry - Función de callback para manejar cada entrada de rendimiento.
 */
const reportWebVitals = async (onPerfEntry?: ReportHandler) => {
  if (typeof onPerfEntry === 'function') {
    try {
      // Mide varias métricas de rendimiento web y las reporta mediante la función de callback proporcionada
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    } catch (error) {
      console.error("Error al cargar las métricas vitales de la web", error);
    }
  }
};

export default reportWebVitals;
