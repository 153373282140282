import React, { useState, useEffect } from 'react';
import App from './App';
import CookieConsentComponent from './components/CookieConsentComponent';
import WarningModal from './components/WarningModal';

const RootComponent: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const [navigationEntry] = performance.getEntriesByType("navigation") as PerformanceNavigationTiming[];
    const navigationType = navigationEntry?.type;

    if (navigationType === "reload" || navigationType === "navigate") {
      setModalOpen(true);
    } else {
      const hasVisited = sessionStorage.getItem('hasVisited');
      if (!hasVisited) {
        setModalOpen(true);
        sessionStorage.setItem('hasVisited', 'true');
      }
    }
  }, []);

  const handleClose = () => setModalOpen(false);

  return (
    <>
      <React.StrictMode>
        <App />
        <CookieConsentComponent />
        <WarningModal open={modalOpen} handleClose={handleClose} />
      </React.StrictMode>
    </>
  );
};

export default RootComponent;
